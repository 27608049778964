import * as React from 'react';
import { useEffect, useState } from 'react';

const PlaybookList: React.FC = () => {
  const [playbooks, setPlaybooks] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchPlaybooks = async () => {
      try {
        // ignore eslint error for not supporting opera mini browser
        /* eslint-disable-next-line compat/compat */
        const response = await fetch('http://localhost:4000/playbooks');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (isMounted) {
          setPlaybooks(data);
        }
      } catch (err: any) {
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchPlaybooks();

    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) {
    return (
      <div style={styles.container}>
        <div style={styles.loading}>Loading your playbooks...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={styles.container}>
        <div style={styles.error}>{error}</div>
      </div>
    );
  }

  if (playbooks.length === 0) {
    return (
      <div style={styles.container}>
        <div style={styles.emptyMessage}>No playbooks found.</div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Your Playbooks</h2>
      <div style={styles.scrollContainer}>
        <ul style={styles.list}>
          {(playbooks ?? []).map(playbook => (
            <li key={playbook._id} style={styles.listItem}>
              <div style={styles.playbookName}>{playbook.name}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    maxWidth: '600px',
    margin: '2rem auto',
    padding: '1rem',
    backgroundColor: '#fafafa',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
    fontFamily: 'sans-serif',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    marginBottom: '1rem',
    borderBottom: '1px solid #eee',
    paddingBottom: '0.5rem',
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#333'
  },
  scrollContainer: {
    maxHeight: '300px', // Adjust as needed
    overflowY: 'auto',
    scrollbarWidth: 'thin' // For Firefox
  },
  list: {
    listStyle: 'none',
    paddingLeft: 0,
    margin: 0
  },
  listItem: {
    padding: '0.75rem',
    borderBottom: '1px solid #f0f0f0',
    display: 'flex',
    alignItems: 'center',
    transition: 'background-color 0.2s ease',
    cursor: 'pointer'
  },
  playbookName: {
    fontSize: '1rem',
    color: '#333',
    fontWeight: 500
  },
  loading: {
    fontSize: '1rem',
    color: '#555',
    textAlign: 'center',
    margin: '2rem 0'
  },
  error: {
    color: '#b00',
    fontSize: '1rem',
    textAlign: 'center',
    margin: '2rem 0'
  },
  emptyMessage: {
    fontSize: '1rem',
    color: '#555',
    textAlign: 'center',
    margin: '2rem 0'
  }
};

// Add a subtle hover effect on list items using inline styles:
// styles.listItem[':hover'] = {
//   backgroundColor: '#f9f9f9'
// };

export default PlaybookList;
