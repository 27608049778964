import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';

const title = 'Legisway Analyzer Add-in';

const rootElement: HTMLElement | null = document.getElementById('container');
const root = rootElement ? createRoot(rootElement) : undefined;

/* Render application after Office initializes */
Office.onReady(() => {
  root?.render(
    <FluentProvider theme={webLightTheme}>
      <App title={title} />
    </FluentProvider>
  );
});

if ((module as any).hot) {
  (module as any).hot.accept('./components/App', async () => {
    const { default: NextApp } = await import('./components/App');
    root?.render(<NextApp title={title} />);
  });
}
