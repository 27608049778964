import { makeStyles } from '@fluentui/react-components';
import { DesignIdeas24Regular, LockOpen24Regular, Ribbon24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { insertText } from '../taskpane';
import Header from './Header';
import HeroList, { HeroListItem } from './HeroList';
import PlaybookList from './PlaybookList';
import TextInsertion from './TextInsertion';

interface AppProps {
  title: string;
}

const useStyles = makeStyles({
  root: {
    minHeight: '100vh'
  }
});

const App: React.FC<AppProps> = (props: AppProps) => {
  const styles = useStyles();
  // The list items are static and won't change at runtime,
  // so this should be an ordinary const, not a part of state.
  const listItems: HeroListItem[] = [
    {
      icon: <Ribbon24Regular />,
      primaryText: 'Achieve more with Office integration'
    },
    {
      icon: <LockOpen24Regular />,
      primaryText: 'Un lock features and functionality'
    },
    {
      icon: <DesignIdeas24Regular />,
      primaryText: 'Create and visualize like a pro'
    }
  ];

  return (
    <div className={styles.root}>
      <Header logo="assets/logo-filled.png" title={props.title} message="Welcome" />
      <HeroList message="Discover what this add-in can do for you today!" items={listItems} />
      <PlaybookList />
      <TextInsertion insertText={insertText} />
    </div>
  );
};

export default App;
